const translations = {
    EN: {
        "new_user": "At the moment, you need to earn at least %s credits in the Make Money app to get on today's leaderboard. The higher your ranking at the end of the day, the higher your reward.",
        "your_ranking": "Your Ranking",
        "your_reward": "Your Reward",
        "ends": "Day Ends",
        "last_updated": "Last updated",
        "user": "User",
        "earned": "Earned",
        "reward": "Reward",
        "instructions": "Earn more credits in the Make Money app to defend your ranking and to top today's leaderboard. The higher your ranking at the end of the day, the higher your reward.",
        "you": "You"
    },
    CA: {
        "new_user": "De moment, heu d'aconseguir almenys %s crèdits a l'aplicació Make Money per accedir a la classificació d'avui. Com més alta sigui la classificació al final del dia, més gran serà la recompensa.",
        "your_ranking": "La teva classificació",
        "your_reward": "La teva recompensa",
        "ends": "Final del dia",
        "last_updated": "Última actualització",
        "user": "Usuari",
        "earned": "Guanyat",
        "reward": "Recompensa",
        "instructions": "Aconsegueix més crèdits a l'aplicació Make Money per defensar la teva classificació i per encapçalar la classificació d'avui. Com més alta sigui la classificació al final del dia, més gran serà la recompensa.",
        "you": "Vostè"
    },
    ZH: {
        "new_user": "目前，您需要在Make Money应用中获得至少%s积分才能进入今天的排行榜。您在一天结束时的排名越高，您的奖励就越高。",
        "your_ranking": "你的排名",
        "your_reward": "你的奖励",
        "ends": "一天结束",
        "last_updated": "最近更新时间",
        "user": "用户",
        "earned": "赢得",
        "reward": "报酬",
        "instructions": "在Make Money应用程序中赚取更多积分以捍卫您的排名并在今天的排行榜上名列前茅。您在一天结束时的排名越高，您的奖励就越高。",
        "you": "你"
    },
    'ZH-HANT': {
        "new_user": "目前，您需要在Make Money應用中獲得至少%s積分才能進入今天的排行榜。您在一天結束時的排名越高，您的獎勵就越高。",
        "your_ranking": "你的排名",
        "your_reward": "你的獎勵",
        "ends": "一天結束",
        "last_updated": "最近更新時間",
        "user": "用戶",
        "earned": "贏得",
        "reward": "報酬",
        "instructions": "在Make Money應用程序中賺取更多積分以捍衛您的排名並在今天的排行榜上名列前茅。您在一天結束時的排名越高，您的獎勵就越高。",
        "you": "你"
    },
    HR: {
        "new_user": "Trenutačno morate zaraditi najmanje %s kredita u aplikaciji Make Money da biste došli na današnju ljestvicu najboljih. Što je viši vaš rang na kraju dana, to je veća vaša nagrada.",
        "your_ranking": "Vaše rangiranje",
        "your_reward": "Vaša nagrada",
        "ends": "Kraj dana",
        "last_updated": "Zadnje ažuriranje",
        "user": "Korisnik",
        "earned": "Zarađeno",
        "reward": "Nagrada",
        "instructions": "Zaradite više kredita u aplikaciji Make Money kako biste obranili svoj poredak i došli na vrh današnje ljestvice. Što je viši vaš rang na kraju dana, to je veća vaša nagrada.",
        "you": "Vas"
    },
    CS: {
        "new_user": "V tuto chvíli potřebujete získat alespoň %s kreditů v aplikaci Make Money, abyste se dostali do dnešního žebříčku. Čím vyšší je vaše hodnocení na konci dne, tím vyšší je vaše odměna.",
        "your_ranking": "Vaše hodnocení",
        "your_reward": "Vaše odměna",
        "ends": "Konec dne",
        "last_updated": "Naposledy aktualizováno",
        "user": "Uživatel",
        "earned": "Vydělané",
        "reward": "Odměna",
        "instructions": "Získejte více kreditů v aplikaci Make Money, abyste obhájili své hodnocení a dostali se na vrchol dnešního žebříčku. Čím vyšší je vaše hodnocení na konci dne, tím vyšší je vaše odměna.",
        "you": "Vy"
    },
    DA: {
        "new_user": "I øjeblikket skal du optjene mindst %s kreditter i Make Money appen for at komme på dagens rangliste. Jo højere din placering ved slutningen af dagen, jo højere din belønning.",
        "your_ranking": "Din placering",
        "your_reward": "Din belønning",
        "ends": "Slutningen på dagen",
        "last_updated": "Sidst opdateret",
        "user": "Bruger",
        "earned": "Optjent",
        "reward": "Belønning",
        "instructions": "Tjen flere kreditter i Make Money appen for at forsvare din placering og for at toppe dagens rangliste. Jo højere din placering ved slutningen af dagen, jo højere din belønning.",
        "you": "Du"
    },
    NL: {
        "new_user": "Op dit moment moet je ten minste %s credits verdienen in de Make Money app om op het leaderboard van vandaag te komen. Hoe hoger je ranking aan het eind van de dag, hoe hoger je beloning.",
        "your_ranking": "Uw positie",
        "your_reward": "Je beloning",
        "ends": "Einde van de dag",
        "last_updated": "Laatst bijgewerkt",
        "user": "Gebruiker",
        "earned": "Verdiend",
        "reward": "Beloning",
        "instructions": "Verdien meer credits in de Make Money app om je positie te verdedigen en bovenaan het klassement van vandaag te komen. Hoe hoger je ranking aan het eind van de dag, hoe hoger je beloning.",
        "you": "Jij"
    },
    FI: {
        "new_user": "Tällä hetkellä sinun on ansaittava vähintään %s krediittiä Make Money sovelluksessa päästäksesi tämän päivän tulostaulukkoon. Mitä korkeammalle sijoituksesi päivän päätteeksi, sitä korkeampi palkkiosi.",
        "your_ranking": "Sijoituksesi",
        "your_reward": "Sinun palkintosi",
        "ends": "Päivän loppu",
        "last_updated": "Viimeksi päivitetty",
        "user": "Käyttäjä",
        "earned": "Ansaittu",
        "reward": "Palkinto",
        "instructions": "Ansaitse lisää krediittejä Make Money sovelluksessa puolustaaksesi sijoitustasi ja noustaksesi tämän päivän tulostaulukon kärkeen. Mitä korkeammalle sijoituksesi päivän päätteeksi, sitä korkeampi palkkiosi.",
        "you": "Sinä"
    },
    FR: {
        "new_user": "Pour le moment, vous devez gagner au moins %s crédits dans l'application Make Money pour figurer dans le classement d'aujourd'hui. Plus votre classement est élevé à la fin de la journée, plus votre récompense est élevée.",
        "your_ranking": "Votre classement",
        "your_reward": "Ta récompense",
        "ends": "Fin de la journée",
        "last_updated": "Dernière mise à jour",
        "user": "Utilisateur",
        "earned": "Gagné",
        "reward": "Récompense",
        "instructions": "Gagnez plus de crédits dans l'application Make Money pour défendre votre classement et être en tête du classement d'aujourd'hui. Plus votre classement est élevé à la fin de la journée, plus votre récompense est élevée.",
        "you": "Tu"
    },
    DE: {
        "new_user": "Im Moment müssen Sie mindestens %s Credits in der Make Money App verdienen, um in die heutige Bestenliste aufgenommen zu werden. Je höher Ihr Rang am Ende des Tages ist, desto höher ist Ihre Belohnung.",
        "your_ranking": "Ihr Rang",
        "your_reward": "Ihre Belohnung",
        "ends": "Ende des Tages",
        "last_updated": "Zuletzt aktualisiert",
        "user": "Nutzer",
        "earned": "Verdient",
        "reward": "Belohnung",
        "instructions": "Verdienen Sie mehr Credits in der Make Money App, um Ihren Rang zu verteidigen und die heutige Bestenliste anzuführen. Je höher Ihr Rang am Ende des Tages ist, desto höher ist Ihre Belohnung.",
        "you": "Sie"
    },
    EL: {
        "new_user": "Προς το παρόν, πρέπει να κερδίσετε τουλάχιστον %s πιστώσεις στην εφαρμογή Make Money για να μπείτε στο σημερινό leaderboard. Όσο υψηλότερη είναι η κατάταξή σας στο τέλος της ημέρας, τόσο μεγαλύτερη είναι η ανταμοιβή σας.",
        "your_ranking": "Η κατάταξή σας",
        "your_reward": "Η ανταμοιβή σας",
        "ends": "Τέλος της ημέρας",
        "last_updated": "Τελευταία ενημέρωση",
        "user": "Χρήστης",
        "earned": "Κερδηθείς",
        "reward": "Ανταμοιβή",
        "instructions": "Κερδίστε περισσότερες πιστώσεις στην εφαρμογή Make Money για να υπερασπιστείτε την κατάταξή σας και να κατακτήσετε την κορυφή του σημερινού βαθμολογικού πίνακα. Όσο υψηλότερη είναι η κατάταξή σας στο τέλος της ημέρας, τόσο μεγαλύτερη είναι η ανταμοιβή σας.",
        "you": "Εσείς"
    },
    HI: {
        "new_user": "इस समय, आज के लीडरबोर्ड पर आने के लिए आपको Make Money ऐप में कम से कम %s क्रेडिट अर्जित करने की आवश्यकता है। दिन के अंत में आपकी रैंकिंग जितनी अधिक होगी, आपका इनाम उतना ही अधिक होगा।",
        "your_ranking": "आपकी रैंकिंग",
        "your_reward": "तुमहारा इनाम",
        "ends": "दिन की समाप्ती",
        "last_updated": "आखरी अपडेट",
        "user": "उपयोगकर्ता",
        "earned": "अर्जित",
        "reward": "इनाम",
        "instructions": "अपनी रैंकिंग की रक्षा करने और आज के लीडरबोर्ड में शीर्ष पर पहुंचने के लिए Make Money ऐप में अधिक क्रेडिट अर्जित करें। दिन के अंत में आपकी रैंकिंग जितनी अधिक होगी, आपका इनाम उतना ही अधिक होगा।",
        "you": "आप"
    },
    HU: {
        "new_user": "Jelenleg legalább %s kreditet kell szereznie a Make Money alkalmazásban, hogy felkerüljön a mai ranglistára. Minél magasabb a helyezésed a nap végén, annál magasabb a jutalom.",
        "your_ranking": "Az Ön helyezése",
        "your_reward": "Az Ön jutalma",
        "ends": "A nap vége",
        "last_updated": "Utolsó frissítés",
        "user": "Felhasználó",
        "earned": "Megszerzett",
        "reward": "Jutalom",
        "instructions": "Szerezzen több kreditet a Make Money alkalmazásban, hogy megvédje helyezését és a mai ranglista élére kerüljön. Minél magasabb a helyezésed a nap végén, annál magasabb a jutalom.",
        "you": "Ön"
    },
    ID: {
        "new_user": "Saat ini, Anda harus mendapatkan setidaknya %s kredit di aplikasi Make Money untuk masuk ke papan peringkat hari ini. Semakin tinggi peringkat Anda di penghujung hari, semakin tinggi hadiah Anda.",
        "your_ranking": "Peringkat Anda",
        "your_reward": "Hadiah Anda",
        "ends": "Akhir Hari",
        "last_updated": "Terakhir diperbarui",
        "user": "Pengguna",
        "earned": "diperoleh",
        "reward": "Penghargaan",
        "instructions": "Dapatkan lebih banyak kredit di aplikasi Make Money untuk mempertahankan peringkat Anda dan menjadi yang teratas di papan peringkat hari ini. Semakin tinggi peringkat Anda di penghujung hari, semakin tinggi hadiah Anda.",
        "you": "Anda"
    },
    IT: {
        "new_user": "Al momento, devi guadagnare almeno %s crediti nell'app Make Money per entrare nella classifica di oggi. Più alta è la tua classifica alla fine della giornata, maggiore sarà la tua ricompensa.",
        "your_ranking": "La tua classifica",
        "your_reward": "Il tuo premio",
        "ends": "Fine del giorno",
        "last_updated": "Ultimo aggiornamento",
        "user": "Utente",
        "earned": "Guadagnato",
        "reward": "Ricompensa",
        "instructions": "Guadagna più crediti nell'app Make Money per difendere la tua classifica e scalare la classifica di oggi. Più alta è la tua classifica alla fine della giornata, maggiore sarà la tua ricompensa.",
        "you": "Voi"
    },
    JA: {
        "new_user": "現在、今日のリーダーボードに参加するには、 Make Moneyアプリで少なくとも%sクレジットを獲得する必要があります。 1 日の終わりのランキングが高いほど、報酬も高くなります。",
        "your_ranking": "あなたのランキング",
        "your_reward": "あなたの報酬",
        "ends": "一日の終わり",
        "last_updated": "最終更新",
        "user": "ユーザー",
        "earned": "獲得",
        "reward": "褒美",
        "instructions": "Make Moneyアプリでさらにクレジットを獲得してランキングを守り、今日のリーダーボードのトップを目指しましょう。 1 日の終わりのランキングが高いほど、報酬も高くなります。",
        "you": "君は"
    },
    KO: {
        "new_user": "현재 순위표에 오르려면 Make Money 앱에서 최소 %s 개의 크레딧을 획득해야 합니다. 하루가 끝날 때 순위가 높을수록 보상이 높아집니다.",
        "your_ranking": "당신의 순위",
        "your_reward": "당신의 보상",
        "ends": "하루의 끝",
        "last_updated": "마지막 업데이트",
        "user": "사용자",
        "earned": "획득",
        "reward": "보상",
        "instructions": "Make Money 앱에서 더 많은 크레딧을 획득하여 순위를 방어하고 오늘의 순위표에서 1위를 차지하세요. 하루가 끝날 때 순위가 높을수록 보상이 높아집니다.",
        "you": "너"
    },
    MS: {
        "new_user": "Pada masa ini, anda perlu memperoleh sekurang-kurangnya %s kredit dalam apl Make Money untuk mencapai papan pendahulu hari ini. Semakin tinggi kedudukan anda pada penghujung hari, semakin tinggi ganjaran anda.",
        "your_ranking": "Kedudukan Anda",
        "your_reward": "Ganjaran anda",
        "ends": "Penghujung Hari",
        "last_updated": "Kemas kini terakhir",
        "user": "pengguna",
        "earned": "Diperolehi",
        "reward": "Ganjaran",
        "instructions": "Peroleh lebih banyak kredit dalam apl Make Money untuk mempertahankan kedudukan anda dan mengungguli papan pendahulu hari ini. Semakin tinggi kedudukan anda pada penghujung hari, semakin tinggi ganjaran anda.",
        "you": "awak"
    },
    NB: {
        "new_user": "For øyeblikket må du tjene minst %s kreditter i Make Money appen for å komme på dagens ledertavle. Jo høyere rangering på slutten av dagen, jo høyere belønning.",
        "your_ranking": "Din rangering",
        "your_reward": "Din belønning",
        "ends": "Slutten av dagen",
        "last_updated": "Sist oppdatert",
        "user": "Bruker",
        "earned": "Opptjent",
        "reward": "Belønning",
        "instructions": "Tjen flere kreditter i Make Money appen for å forsvare rangeringen din og toppe dagens ledertavle. Jo høyere rangering på slutten av dagen, jo høyere belønning.",
        "you": "Du"
    },
    PL: {
        "new_user": "W tej chwili musisz zdobyć co najmniej %s kredytów w aplikacji Make Money, aby dostać się do dzisiejszej tabeli liderów. Im wyższy ranking na koniec dnia, tym wyższa nagroda.",
        "your_ranking": "Twój ranking",
        "your_reward": "Twoja nagroda",
        "ends": "Koniec dnia",
        "last_updated": "Ostatnio zaktualizowany",
        "user": "Użytkownik",
        "earned": "Zdobyte",
        "reward": "Nagroda",
        "instructions": "Zdobądź więcej punktów w aplikacji Make Money aby obronić swój ranking i znaleźć się na szczycie dzisiejszej tabeli liderów. Im wyższy ranking na koniec dnia, tym wyższa nagroda.",
        "you": "Ty"
    },
    PT: {
        "new_user": "No momento, você precisa ganhar pelo menos %s créditos no aplicativo Make Money para entrar na tabela de classificação de hoje. Quanto maior a sua classificação no final do dia, maior a sua recompensa.",
        "your_ranking": "Sua classificação",
        "your_reward": "Sua recompensa",
        "ends": "Fim do dia",
        "last_updated": "Ultima atualização",
        "user": "Do utilizador",
        "earned": "Merecido",
        "reward": "Recompensa",
        "instructions": "Ganhe mais créditos no aplicativo Make Money para defender sua classificação e ficar no topo da tabela de classificação de hoje. Quanto maior a sua classificação no final do dia, maior a sua recompensa.",
        "you": "Você"
    },
    RO: {
        "new_user": "În acest moment, trebuie să câștigați cel puțin %s credite în aplicația Make Money pentru a ajunge în clasamentul de astăzi. Cu cât clasamentul tău este mai mare la sfârșitul zilei, cu atât recompensa este mai mare.",
        "your_ranking": "Clasamentul tău",
        "your_reward": "Recompensa ta",
        "ends": "Sfarsitul zilei",
        "last_updated": "Ultima actualizare",
        "user": "Utilizator",
        "earned": "Câștigat",
        "reward": "Răsplată",
        "instructions": "Câștigați mai multe credite în aplicația Make Money pentru a vă apăra clasamentul și pentru a primi clasamentul de astăzi. Cu cât clasamentul tău este mai mare la sfârșitul zilei, cu atât recompensa este mai mare.",
        "you": "Tu"
    },
    RU: {
        "new_user": "На данный момент вам нужно заработать не менее %s кредитов в приложении Make Money, чтобы попасть в сегодняшнюю таблицу лидеров. Чем выше ваш рейтинг в конце дня, тем выше ваша награда.",
        "your_ranking": "Ваш рейтинг",
        "your_reward": "Ваша награда",
        "ends": "Конец дня",
        "last_updated": "Последнее обновление",
        "user": "Пользователь",
        "earned": "Заработано",
        "reward": "Награда",
        "instructions": "Зарабатывайте больше кредитов в приложении Make Money, чтобы защитить свой рейтинг и занять первое место в сегодняшней таблице лидеров. Чем выше ваш рейтинг в конце дня, тем выше ваша награда.",
        "you": "Ты"
    },
    SK: {
        "new_user": "V súčasnosti potrebujete získať aspoň %s kreditov v aplikácii Make Money, aby ste sa dostali do dnešného rebríčka. Čím vyššie bude vaše hodnotenie na konci dňa, tým vyššia bude vaša odmena.",
        "your_ranking": "Vaše hodnotenie",
        "your_reward": "Vaša odmena",
        "ends": "Koniec dňa",
        "last_updated": "Naposledy aktualizovaný",
        "user": "Používateľ",
        "earned": "Zarobené",
        "reward": "Odmena",
        "instructions": "Získajte viac kreditov v aplikácii Make Money, aby ste obhájili svoje hodnotenie a dostali sa na čelo dnešného rebríčka. Čím vyššie bude vaše hodnotenie na konci dňa, tým vyššia bude vaša odmena.",
        "you": "Vy"
    },
    ES: {
        "new_user": "Por el momento, debe ganar al menos %s créditos en la aplicación Make Money para estar en la clasificación de hoy. Cuanto mayor sea su clasificación al final del día, mayor será su recompensa.",
        "your_ranking": "Tu Clasificación",
        "your_reward": "Tu recompensa",
        "ends": "Fin del día",
        "last_updated": "Última actualización",
        "user": "Usuario",
        "earned": "Ganado",
        "reward": "Premio",
        "instructions": "Obtenga más créditos en la aplicación Make Money para defender su clasificación y encabezar la clasificación de hoy. Cuanto mayor sea su clasificación al final del día, mayor será su recompensa.",
        "you": "Tú"
    },
    SV: {
        "new_user": "För tillfället måste du tjäna minst %s krediter i Make Money appen för att komma på dagens topplista. Ju högre din ranking i slutet av dagen, desto högre blir din belöning.",
        "your_ranking": "Din ranking",
        "your_reward": "Din belöning",
        "ends": "Slutet på dagen",
        "last_updated": "Senast uppdaterad",
        "user": "Användare",
        "earned": "Tjänade",
        "reward": "Pris",
        "instructions": "Tjäna fler poäng i Make Money appen för att försvara din ranking och toppa dagens topplista. Ju högre din ranking i slutet av dagen, desto högre blir din belöning.",
        "you": "Du"
    },
    TH: {
        "new_user": "ในขณะนี้ คุณต้องได้รับเครดิตอย่างน้อย %s ในแอป Make Money เพื่อเข้าสู่ลีดเดอร์บอร์ดของวันนี้ ยิ่งอันดับของคุณสูงขึ้นเมื่อสิ้นสุดวัน รางวัลของคุณก็จะยิ่งสูงขึ้น",
        "your_ranking": "อันดับของคุณ",
        "your_reward": "รางวัลของคุณ",
        "ends": "วันสิ้นโลก",
        "last_updated": "อัพเดทล่าสุด",
        "user": "ผู้ใช้",
        "earned": "ได้รับ",
        "reward": "รางวัล",
        "instructions": "รับเครดิตมากขึ้นในแอป Make Money เพื่อปกป้องอันดับของคุณและขึ้นเป็นผู้นำในกระดานผู้นำของวันนี้ ยิ่งอันดับของคุณสูงขึ้นเมื่อสิ้นสุดวัน รางวัลของคุณก็จะยิ่งสูงขึ้น",
        "you": "คุณ"
    },
    TR: {
        "new_user": "Şu anda, bugünün skor tablosunda yer almak için Make Money uygulamasında en az %s kredi kazanmanız gerekiyor. Günün sonunda sıralamanız ne kadar yüksek olursa, ödülünüz de o kadar yüksek olur.",
        "your_ranking": "Sıralamanız",
        "your_reward": "Ödülün",
        "ends": "Günün sonu",
        "last_updated": "Son güncelleme",
        "user": "kullanıcı",
        "earned": "kazanılan",
        "reward": "Ödül",
        "instructions": "Sıralamanızı korumak ve bugünün lider panosunda zirveye çıkmak için Make Money uygulamasında daha fazla kredi kazanın. Günün sonunda sıralamanız ne kadar yüksek olursa, ödülünüz de o kadar yüksek olur.",
        "you": "Sen"
    },
    UK: {
        "new_user": "На даний момент вам потрібно отримати принаймні %s кредитів у програмі Make Money, щоб потрапити до сьогоднішньої таблиці лідерів. Чим вищий ваш рейтинг наприкінці дня, тим більша ваша винагорода.",
        "your_ranking": "Ваш рейтинг",
        "your_reward": "Ваша винагорода",
        "ends": "Кінець дня",
        "last_updated": "Останнє оновлення",
        "user": "Користувач",
        "earned": "Зароблено",
        "reward": "Нагорода",
        "instructions": "Заробляйте більше кредитів у додатку Make Money, щоб захистити свій рейтинг і очолити сьогоднішню таблицю лідерів. Чим вищий ваш рейтинг наприкінці дня, тим більша ваша винагорода.",
        "you": "Ти"
    },
    VI: {
        "new_user": "Hiện tại, bạn cần kiếm được ít nhất %s tín dụng trong ứng dụng Make Money để có được bảng xếp hạng ngày hôm nay. Xếp hạng của bạn vào cuối ngày càng cao, phần thưởng của bạn càng cao.",
        "your_ranking": "Xếp hạng của bạn",
        "your_reward": "Phần thưởng của bạn",
        "ends": "Cuối ngày",
        "last_updated": "Cập nhật mới nhất",
        "user": "Người sử dụng",
        "earned": "Kiếm được",
        "reward": "Giải thưởng",
        "instructions": "Kiếm thêm tín dụng trong ứng dụng Make Money để bảo vệ thứ hạng của bạn và đứng đầu bảng xếp hạng của ngày hôm nay. Xếp hạng của bạn vào cuối ngày càng cao, phần thưởng của bạn càng cao.",
        "you": "Bạn"
    }
};

module.exports = translations;
